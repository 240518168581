import Vue from 'vue'
import Vuex from 'vuex'

import { createAuthStoreModule } from 'kayleen-vue-auth0-client'

import auth0Settings from '@/auth0-settings'
import baseStore from './base-store'
import localStore from './local-store'

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
  },
  getters: {
  },
  modules: {
    auth: createAuthStoreModule(auth0Settings),
    base: baseStore,
    local: localStore
  },
  mutations: {
  },
  state: {
  }
})